import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiFunctions from '../../apiKit/api';

// Async Thunk to call multiple APIs, including custom API functions
export const fetchAPIs = createAsyncThunk('api/fetchAPIs', async () => {
  const [userResponse] = await Promise.all([
    apiFunctions.getallUserApi(),
  ]);
  const [faqResponse] = await Promise.all([
    apiFunctions.getallFaq(),
  ]);
  const [privacyResponse] = await Promise.all([
    apiFunctions.getPrivacy(),
  ]);
  const [termsResponse] = await Promise.all([
    apiFunctions.getTermsApi(),
  ]);
  const [settingResponse] = await Promise.all([
    apiFunctions.getSettings(),
  ]);
  const [creatorResponse] = await Promise.all([
    apiFunctions.getcreatorsApi(),
  ]);
  const [InterestResponse] = await Promise.all([
    apiFunctions.getallInterestsApi(),
  ]);
  const [NotificationResponse] = await Promise.all([
    apiFunctions.getallNotificationsApi(),
  ]);
  const [VideoReportResponse] = await Promise.all([
    apiFunctions.getVideoReports(),
  ]);
  const [userReportResponse] = await Promise.all([
    apiFunctions.getUserReports(),
  ]);
  const [topcreatorResponse] = await Promise.all([
    apiFunctions.getTopcreatorsApi(),
  ]);
  const [InviteUserResponse] = await Promise.all([
    apiFunctions.getallInviteUserApi(),
  ]);
  const [yearResponse] = await Promise.all([
    apiFunctions.getChartYearsApi(),
  ]);
  const [videoyearResponse] = await Promise.all([
    apiFunctions.getChartVideoYearsApi(),
  ]);
  const [payyearResponse] = await Promise.all([
    apiFunctions.getChartPaymentYearsApi(),
  ]);

  let userdata = [];
  let Inviteuserdata = [];
  let creatordata = [];
  let faqdata = [];
  let privacydata = [];
  let terms = [];
  let settingdata = [];
  let Interestdata = [];
  let Notificationdata = [];

  let reportVideodata = [];
  let reportUserdata = [];
  let topCreatordata = [];
  let chartYeardata = [];
  let chartVideoYeardata = [];
  let chartPayYeardata = [];





  if (userResponse.status === 200) {
    userdata = userResponse?.data?.data;
  }
  if (faqResponse.status === 200) {
    faqdata = faqResponse?.data?.data;
  }
  if (privacyResponse.status === 200) {
    privacydata = privacyResponse?.data?.data;
  }
  if (termsResponse.status === 200) {
    terms = termsResponse?.data?.data;
  }
  if (settingResponse.status === 200) {
    settingdata = settingResponse?.data?.data;
  }
  if (creatorResponse.status === 200) {
    creatordata = creatorResponse?.data?.data;
  }
  if (InterestResponse.status === 200) {
    Interestdata = InterestResponse?.data?.data;
  }
  if (NotificationResponse.status === 200) {
    Notificationdata = NotificationResponse?.data?.data;
  }
  if (VideoReportResponse.status === 200) {
    reportVideodata = VideoReportResponse?.data?.data;
  }
  if (userReportResponse.status === 200) {
    reportUserdata = userReportResponse?.data?.data;
  }
  console.log(topcreatorResponse, "topcreatorResponse");

  if (topcreatorResponse.status === 200) {
    topCreatordata = topcreatorResponse?.data?.data;
  }
  if (yearResponse.status === 200) {
    chartYeardata = yearResponse?.data?.data;
  }
  if (videoyearResponse.status === 200) {
    chartVideoYeardata = videoyearResponse?.data?.data;
  }
  if (payyearResponse.status === 200) {
    chartPayYeardata = payyearResponse?.data?.data;
  }
  if (InviteUserResponse.status === 200) {
    Inviteuserdata = InviteUserResponse?.data?.data;
  }

  return {
    userdata, faqdata, privacydata, terms, settingdata, Interestdata, creatordata, reportVideodata, reportUserdata,
    topCreatordata, Inviteuserdata, chartYeardata, Notificationdata, chartVideoYeardata, chartPayYeardata
  };
});

const teapoyApiSlice = createSlice({
  name: 'teapoyapi',
  initialState: {
    userdata: [],
    Inviteuserdata: [],
    creatordata: [],
    faqdata: [],
    privacydata: [],
    termsdata: [],
    settingdata: [],
    Interestdata: [],
    Notificationdata: [],
    reportVideodata: [],
    reportUserdata: [],
    topCreatordata: [],
    chartYeardata: [],
    chartVideoYeardata: [],
    chartPayYeardata: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAPIs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAPIs.fulfilled, (state, action) => {
        state.loading = false;
        state.userdata = action.payload.userdata;
        state.creatordata = action.payload.creatordata;
        state.faqdata = action.payload.faqdata;
        state.privacydata = action.payload.privacydata;
        state.termsdata = action.payload.terms;
        state.settingdata = action.payload.settingdata;
        state.Interestdata = action.payload.Interestdata;
        state.Notificationdata = action.payload.Notificationdata;
        state.reportVideodata = action.payload.reportVideodata;
        state.reportUserdata = action.payload.reportUserdata;
        state.topCreatordata = action.payload.topCreatordata;
        state.Inviteuserdata = action.payload.Inviteuserdata;
        state.chartYeardata = action.payload.chartYeardata;
        state.chartPayYeardata = action.payload.chartPayYeardata;
        state.chartVideoYeardata = action.payload.chartVideoYeardata;
      })
      .addCase(fetchAPIs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default teapoyApiSlice.reducer;
