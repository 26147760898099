import React, { useEffect, useState } from "react";
import SideBar from "../../components/Sidebar";
import { Button, Card, CardContent, IconButton } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import "../../../src/custom.css";
import Loader from "../../components/Loader";
import Dropdown from "../../components/dropdown";
import ReactQuill from 'react-quill'; // Import ReactQuill
import 'react-quill/dist/quill.snow.css'; // Import Quill CSS
import apiFunctions from "../../apiKit/api";
import { useDispatch, useSelector } from 'react-redux';
import Swal from "sweetalert2";
import { fetchAPIs } from "../../redux/slice/slice";


const PrivacyEdit = () => {
    const [isOpen, setIsopen] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [editorContent, setEditorContent] = useState(``);
    const { privacydata } = useSelector((state) => state.api);
    const [edit, setEdit] = useState(false);

    const dispatch = useDispatch();

    const handleOpen = () => {
        setIsopen(!isOpen);
    }

    const handleCancel = () => {
        setEditorContent(privacydata[0]?.content);
    }

    const handleSubmit = () => {
        console.log("Submitted content:", editorContent);
        apiFunctions.updatePrivacy({ "content": editorContent }, privacydata[0]?._id).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    text: res.message,
                    icon: 'success',
                });
                dispatch(fetchAPIs());
            } else {
                Swal.fire({
                    text: res.message,
                    icon: 'error',
                });
            }
        }).catch(() => {
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error',
            });
        });
    }

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
            setEditorContent(privacydata[0].content);
        }, 2000);
    }, [privacydata]);

    return (
        <div className="container-fluid p-0" style={{ overflow: 'hidden' }}>
            <div className="row">
                <div className={`${isOpen ? "col-lg-2 mob-nav p-0" : "d-none"} sidebar_layout`}>
                    <SideBar />
                </div>
                <div className={`${isOpen ? "col-lg-10 col-12" : "col-12 w-100"} PrivacyEdit_card main_layout`}>
                    <div className='row'>
                        <div className="d-flex w-100 justify sticky-top">
                            <IconButton className="web-btn" onClick={handleOpen}>
                                <MenuIcon />
                            </IconButton>
                            <IconButton className="mob-btn" data-bs-toggle="offcanvas" data-bs-target="#mob-canvas" aria-controls="mob-canvas">
                                <MenuIcon />
                            </IconButton>
                            <div className="logout_dropdown">
                                {/* <Logout /> */}
                                <Dropdown />
                            </div>
                        </div>
                    </div>
                    {/* Page Contents */}
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <div className="row p-lg-4 p-3 mt-3 main">
                            <div className="col-12">
                                <div className="d-flex justify">
                                    <h4>Edit Privacy policy</h4>
                                </div>
                                <Card elevation={6} className="mt-3" >
                                    <CardContent>
                                        <ReactQuill value={editorContent} onChange={(content) => {
                                            setEditorContent(content);
                                            setEdit(true);
                                        }} />
                                        <div className="mt-3 d-flex justify-content-start">
                                            <Button variant="contained" className="grey-btn" color="secondary" onClick={handleCancel} style={{ marginRight: "10px" }}>
                                                Cancel
                                            </Button>
                                            <Button disabled={edit ? false : true} className="btn" variant="contained" color="primary" onClick={handleSubmit}>
                                                Submit
                                            </Button>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="mob-canvas" aria-labelledby="mob-canvaslabel">
                <div className="offcanvas-header">
                    <IconButton data-bs-dismiss="offcanvas" aria-label="Close">
                        <CloseIcon style={{ height: '40px', width: '40px', color: 'white' }} />
                    </IconButton>
                </div>
                <div className="offcanvas-body p-0">
                    <SideBar />
                </div>
            </div>
        </div>
    );
};

export default PrivacyEdit;