import React, { useState, useEffect } from "react";
import { DataGrid } from '@mui/x-data-grid';
import "../assets/css/table.css";
import apiFunctions from "../apiKit/api";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Button, ButtonGroup, Card, CardContent, IconButton,
    Dialog, DialogTitle, DialogContent, DialogActions,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    TextField
} from "@mui/material";
import Swal from "sweetalert2";
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import { useDispatch } from "react-redux";
import { fetchAPIs } from "../redux/slice/slice";
const AdminPaymentHistoryTable = () => {
    const [rows, setRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [rowCount, setRowCount] = useState(0);
    const [isLastPage, setIsLastPage] = useState(false);
    const [isViewOpen, setIsViewOpen] = useState(false);
    const [filter, setFilter] = useState({ creator_email: '', user_email: '', amount: '' });
    const [filteredRows, setFilteredRows] = useState([]);
    const [count, setCount] = useState(0);
    const dispatch = useDispatch();

    const columns = [
        {
            field: 'creator_email', disableColumnMenu: true,
            sortable: false, headerName: 'Creator', flex: 1, resizable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'user_email', disableColumnMenu: true,
            sortable: false, headerName: 'User', flex: 1, resizable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'amount', disableColumnMenu: true,
            sortable: false, headerName: 'Amount($)', flex: 1, resizable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'createdAt', disableColumnMenu: true,
            sortable: false, headerName: 'Created At', flex: 1, resizable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'Action',
            // flex: 1,
            disableColumnMenu: true,
            sortable: false,
            headerName: 'Action',
            renderCell: (params) => (
                <ButtonGroup>
                    <IconButton onClick={() => handleViewOpen(params.row)}>
                        <VisibilityIcon />
                    </IconButton>
                </ButtonGroup>
            )
        }
    ];

    // Fetch data with pagination
    const fetchData = async (pageNo) => {
        setLoading(true);
        try {
            const res = await apiFunctions.getAllPaymenthistory(pageNo);
            if (res.status === 200) {
                if (res.data?.data !== '') {
                    console.log("resssss", res.data.data);
                    setCount(res?.data?.datacount);
                    const formattedRows = res.data?.data?.map((item) => ({
                        id: item._id,
                        subscriptionId: item.subscriptionId,
                        creator_email: item.creator_email,
                        user_email: item.user_email,
                        amount: item.paymentAmount,
                        netamount: item.netamount,
                        createdAt: new Date(item.createdAt).toLocaleDateString(),
                    }));
                    setRows(formattedRows);
                    setRowCount(res.datacount);
                    setIsLastPage(res.isLast);
                    if (res.data?.data?.length < 10) {
                        setIsLastPage(true);
                    }
                } else {
                    setRows([]);
                    setRowCount(0);
                    setIsLastPage(true);
                }
            } else {
                setRows([]);
                setRowCount(0);
                setIsLastPage(true);
            }
        } catch (error) {
            console.error("Error fetching data :", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(page);
    }, [page]);
    useEffect(() => {
        const applyFilters = () => {
            const filtered = rows.filter((row) => {
                return (
                    (!filter.creator_email || row.creator_email?.toLowerCase().includes(filter.creator_email.toLowerCase())) &&
                    (!filter.user_email || row.user_email?.toLowerCase().includes(filter.user_email.toLowerCase())) &&
                    (!filter.amount || row.amount?.toLowerCase().includes(filter.amount.toLowerCase()))
                );
            });
            setFilteredRows(filtered);
        };
        applyFilters();
    }, [filter, rows]);


    const handleViewOpen = (row) => {
        setSelectedRow(row);
        setIsViewOpen(true);
    };

    const handleViewClose = () => {
        setIsViewOpen(false);
        setSelectedRow(null);
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilter({ ...filter, [name]: value });
    };
    const handleClearFilter = () => {
        setFilter({ creator_email: '', user_email: '', amount: '' });
        fetchData(page);
    };

    return (
        <div>
            <Card elevation={4} className="p-0 mt-3">
                <CardContent className="p-0">
                    <h5 className="fw-bold p-3 pb-0">Payment History</h5>
                    <Accordion elevation={3} className="mt-3">
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <h5>Filter Options</h5>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="row mb-3">
                                <div className="col-md-3">
                                    <TextField
                                        label="Filter by Creator Email"
                                        variant="outlined"
                                        fullWidth
                                        name="creator_email"
                                        value={filter.creator_email}
                                        onChange={handleFilterChange}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <TextField
                                        label="Filter by User Email"
                                        variant="outlined"
                                        fullWidth
                                        name="user_email"
                                        value={filter.user_email}
                                        onChange={handleFilterChange}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <TextField
                                        label="Filter by Amount"
                                        variant="outlined"
                                        fullWidth
                                        name="amount"
                                        value={filter.amount}
                                        onChange={handleFilterChange}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <button className="btn" onClick={handleClearFilter} >Clear</button>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <div className="table-container">
                        <DataGrid
                            rows={filteredRows}
                            columns={columns}
                            getRowId={(row) => row.id}
                            loading={loading}
                            disableColumnMenu
                            rowCount={rowCount}
                            localeText={{
                                noRowsLabel: 'No data found',
                            }}
                            autoHeight
                            pagination={false}
                            hideFooter={true}
                        />


                    </div>
                    {/* Custom Pagination */}
                    <div className="pagination">
                        <IconButton
                            disabled={page === 1}
                            onClick={() => setPage(page - 1)}
                        >
                            <KeyboardArrowLeftOutlinedIcon />
                        </IconButton>
                        <span>Page {page}</span>
                        <IconButton
                            disabled={isLastPage}
                            onClick={() => setPage(page + 1)}
                        >
                            <KeyboardArrowRightOutlinedIcon />
                        </IconButton>
                    </div>
                </CardContent>
            </Card>

            {/* View Modal */}
            <Dialog open={isViewOpen} onClose={handleViewClose} fullWidth maxWidth="sm">
                <DialogTitle>Payment Detail</DialogTitle>
                <DialogContent>
                    <table className="table table">
                        <tbody>
                            <tr>
                                <td><strong>Subscription Id :</strong></td>
                                <td>{selectedRow?.subscriptionId}</td>
                            </tr>
                            <tr>
                                <td><strong>Creator :</strong></td>
                                <td>{selectedRow?.creator_email}</td>
                            </tr>
                            <tr>
                                <td><strong>User :</strong></td>
                                <td>{selectedRow?.user_email || "-"}</td>
                            </tr>
                            <tr>
                                <td><strong>Amount($) :</strong></td>
                                <td>{selectedRow?.amount || "-"}</td>
                            </tr>
                            <tr>
                                <td><strong>Creator Received Amount($) :</strong></td>
                                <td>{selectedRow?.netamount || "-"}</td>
                            </tr>
                            <tr>
                                <td><strong>Created At :</strong></td>
                                <td>{selectedRow?.createdAt || "-"}</td>
                            </tr>
                        </tbody>
                    </table>
                </DialogContent>
                <DialogActions>
                    <Button className="btn" onClick={handleViewClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
export default AdminPaymentHistoryTable;
