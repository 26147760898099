import React, { useEffect, useState } from "react";
import SideBar from "../components/Sidebar";
import { Button, ButtonGroup, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Accordion, AccordionSummary, AccordionDetails, Switch, Tooltip } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "../../src/custom.css";
import Table from "../components/Table";
import Swal from "sweetalert2";
import Loader from "../components/Loader";
import Dropdown from "../components/dropdown";
import { useDispatch, useSelector } from 'react-redux';
import EmailIcon from '@mui/icons-material/Email';
import apiFunctions from "../apiKit/api";
import { fetchAPIs } from "../redux/slice/slice";
import ReduxComponent from "../components/reduxData";
import InfoIcon from '@mui/icons-material/Info';
import { pageRoutes } from "../config/route";
const Users = () => {
    const [isOpen, setIsopen] = useState(true);
    const [isViewOpen, setIsViewOpen] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [isNewModalOpen, setIsNewModalOpen] = useState(false);
    const [isCreatorModalOpen, setIsCreatorModalOpen] = useState(false);
    const [userAcceptModalOpen, setUserAcceptModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedRow, setSelectedRow] = useState(null);
    const [filter, setFilter] = useState({ username: '', email: '', phone: '' });
    const [filteredRows, setFilteredRows] = useState([]);
    const { userdata } = useSelector((state) => state.api);
    const dispatch = useDispatch();
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
            // dispatch(fetchAPIs());
        }, 2000);
        console.log("redux user data", userdata);
    }, [userdata]);


    useEffect(() => {
        // dispatch(fetchAPIs());
        const newFilteredRows = userdata?.map(row => ({
            ...row,
            username: row.username || null,
            email: row.email || null,
            phone: row.phone || null
        })).filter(row => {
            return (
                (filter.username === '' || row.username?.toLowerCase().includes(filter.username.toLowerCase())) &&
                (filter.email === '' || row.email?.toLowerCase().includes(filter.email.toLowerCase())) &&
                (filter.phone === '' || row.phone?.toString().includes(filter.phone))
            );
        }) || [];
        setFilteredRows(newFilteredRows);
    }, [filter, userdata]);



    const handleOpen = () => {
        setIsopen(!isOpen);
    };

    const handleViewOpen = (row) => {
        setSelectedRow(row);
        setIsViewOpen(true);
    };

    const handleViewClose = () => {
        setIsViewOpen(false);
        setSelectedRow(null);
    };

    const handleEditOpen = (row) => {
        setSelectedRow(row);
        setIsEditOpen(true);
    };

    const handleEditClose = () => {
        setIsEditOpen(false);
        setSelectedRow(null);
    };

    const handleEditSave = () => {
        // Logic to save the edited data
        setIsEditOpen(false);
        setSelectedRow(null);
        Swal.fire('', 'Saved Successfully', 'success');
    };

    const handleSwitchChange = (row) => {
        setSelectedRow(row);
        setIsNewModalOpen(true);
    };

    const handleCreatorSwitchChange = (row) => {
        setSelectedRow(row);
        setIsCreatorModalOpen(true);
    };

    const handleUserStatus = () => {
        var json = {
            "id": selectedRow?._id,
            "user_status": selectedRow?.user_status === 0 ? 1 : 0
        }
        apiFunctions.userChangestatus(json).then((res) => {
            if (res.status === 200) {
                console.log(res, 'resss');
                setIsNewModalOpen(false);
                Swal.fire({
                    text: res.message,
                    icon: 'success',
                });
                dispatch(fetchAPIs());
            } else {
                setIsNewModalOpen(false);
                Swal.fire({
                    text: res.message,
                    icon: 'error',
                });
            }
        }).catch(() => {
            setIsNewModalOpen(false);
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error',
            });
        });
    }

    const handleCreatorStatus = () => {
        var json = {
            "id": selectedRow?._id,
            "creater_status": parseInt(selectedRow?.creater_status === 1 ? 2 : 1)
        }
        apiFunctions.creatorChangestatus(json).then((res) => {
            if (res.status === 200) {
                console.log(res, 'resss');
                setIsCreatorModalOpen(false);
                Swal.fire({
                    text: res.message,
                    icon: 'success',
                });
                dispatch(fetchAPIs());
            } else {
                setIsCreatorModalOpen(false);
                Swal.fire({
                    text: res.message,
                    icon: 'error',
                });
            }
        }).catch(() => {
            setIsCreatorModalOpen(false);
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error',
            });
        });
    }

    // creator Request handling
    const handleCreatorAcceptReject = (row) => {
        setSelectedRow(row);
        setUserAcceptModalOpen(true);
    };
    const handleUserApprove = (status) => {
        var json = {
            "id": selectedRow?._id,
            "status": status
        }
        apiFunctions.userApprove(json).then((res) => {
            if (res.status === 200) {
                setUserAcceptModalOpen(false);
                Swal.fire({
                    text: res.message,
                    icon: 'success',
                });
                dispatch(fetchAPIs());
            } else {
                setUserAcceptModalOpen(false);
                Swal.fire({
                    text: res.message,
                    icon: 'error',
                });
            }
        }).catch(() => {
            setUserAcceptModalOpen(false);
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error',
            });
        });
    }
    const statusText = {
        0: 'Not provided',
        1: 'Pending',
        2: 'Approved',
        3: 'Rejected',
    };
    const columns = [
        {
            field: 'username',
            headerName: 'Username',
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => params.value ? params.value : '-'
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => params.value ? params.value : '-'
        },
        {
            field: 'Activated',
            headerName: 'Verified',
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => params?.row?.Activated ? "True" : "False"
        },
        {
            sortable: false,
            field: 'phone',
            flex: 1,
            headerName: 'Phone',
            disableColumnMenu: true,
            renderCell: (params) => params.value ? params.value : '-'
        },
        {
            sortable: false,
            field: 'user_status',
            flex: 1,
            headerName: 'Onboard status',
            disableColumnMenu: true,
            renderCell: (params) => {
                const status = params.row.creater;
                return <span>{statusText[status] || '-'}</span>;
            }
        },
        {
            field: 'creator Action',
            flex: 1,
            headerName: 'Approve Creator',
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => {
                const status = params.row.creater;
                const creater_status = params.row.creater_status;

                return (
                    <ButtonGroup>
                        {/* <IconButton onClick={() => handleEditOpen(params.row)}><EditIcon /></IconButton> */}
                        <IconButton disabled={status !== 1} onClick={() => handleCreatorAcceptReject(params.row)}>
                            <EmailIcon color={status === 1 ? "primary" : "action"} />
                        </IconButton>
                        {/* <Switch checked={creater_status === 1} disabled={creater_status === 0}
                            onChange={() => handleCreatorSwitchChange(params.row)}></Switch> */}
                    </ButtonGroup>
                );
            }
        },
        {
            field: 'Action',
            flex: 1,
            headerName: 'Active',
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => {
                const status = params.row.user_status;
                const ac_status = params.row.account_status;
                return (
                    <ButtonGroup style={{ textAlign: 'left', justifyContent: 'flex-start', width: '100%', marginRight: "100px" }}>
                        <IconButton onClick={() => handleViewOpen(params.row)}>
                            <VisibilityIcon />
                        </IconButton>
                        <Switch checked={status === 0}
                            onChange={() => handleSwitchChange(params.row)}></Switch>
                        {ac_status === 1 ?
                            <Tooltip title="This user want to continue again" placement="top">
                                <IconButton color="primary" onClick={() => handleSwitchChange(params.row)}><InfoIcon /></IconButton>
                            </Tooltip> : <span></span>}
                    </ButtonGroup >
                );
            }
        },
    ];


    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilter({ ...filter, [name]: value });
    };
    const handleClearFilter = () => {
        setFilter({ username: '', email: '', phone: '' });
        dispatch(fetchAPIs());
    };

    return (
        <>
            <div className="container-fluid p-0" style={{ overflow: 'hidden' }}>
                <div className="row">
                    <div className={`${isOpen ? "col-lg-2 mob-nav p-0" : "d-none"} sidebar_layout`}>
                        <SideBar />
                    </div>
                    <div className={`${isOpen ? "col-lg-10 col-12" : "col-12 w-100"} dashboard_card main_layout`} >
                        <div className="row">
                            <div className="d-flex w-100 justify">
                                <IconButton className="web-btn" onClick={handleOpen}>
                                    <MenuIcon />
                                </IconButton>
                                <IconButton className="mob-btn" data-bs-toggle="offcanvas" data-bs-target="#mob-canvas" aria-controls="mob-canvas">
                                    <MenuIcon />
                                </IconButton>
                                <div className="logout_dropdown">
                                    {/* <Logout /> */}
                                    <Dropdown />
                                </div>
                            </div>
                        </div>
                        {/* Page Contents */}
                        {isLoading ?
                            <Loader /> :
                            <div className="row p-3 mt-3 main">
                                <div className="col-12">
                                    {/* <Card>
                                        <CardContent> */}
                                    <div className="d-flex justify">
                                        <h4 className="fw-bold">Users</h4>
                                        <h6 className="fw-lighter" typeof="button" onClick={() => window.location.replace(pageRoutes.Inviteusers)} style={{ textDecoration: "underline", color: "#32328E", cursor: "pointer" }}>Invite Requests</h6>
                                    </div>
                                    <Accordion elevation={3} >
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <h5>Filter Options</h5>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className="row mb-3">
                                                <div className="col-md-3">
                                                    <TextField
                                                        label="Filter by Username"
                                                        variant="outlined"
                                                        fullWidth
                                                        name="username"
                                                        value={filter.username}
                                                        onChange={handleFilterChange}
                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    <TextField
                                                        label="Filter by Email"
                                                        variant="outlined"
                                                        fullWidth
                                                        name="email"
                                                        value={filter.email}
                                                        onChange={handleFilterChange}
                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    <TextField
                                                        label="Filter by Phone"
                                                        variant="outlined"
                                                        fullWidth
                                                        name="phone"
                                                        value={filter.phone}
                                                        onChange={handleFilterChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <button className="btn" onClick={handleClearFilter} >Clear</button>
                                                </div>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>

                                    {/* </CardContent>
                                    </Card> */}
                                    <Table rows={filteredRows} columns={columns} pageSize={10} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="mob-canvas" aria-labelledby="mob-canvaslabel">
                <div className="offcanvas-header">
                    <IconButton data-bs-dismiss="offcanvas" aria-label="Close">
                        <CloseIcon style={{ height: '40px', width: '40px', color: 'white' }} />
                    </IconButton>
                </div>
                <div className="offcanvas-body p-0">
                    <SideBar />
                </div>
            </div>
            {/* View Modal */}
            <Dialog className="p-3" open={isViewOpen} onClose={handleViewClose} fullWidth={true} maxWidth="sm" >
                <DialogTitle>User Detail</DialogTitle>
                <DialogContent>
                    {/* <div>
                        <p><strong>ID:</strong> {selectedRow?._id}</p>
                        <p><strong>Username:</strong> {selectedRow?.username}</p>
                        <p><strong>Email:</strong> {selectedRow?.email}</p>
                        <p><strong>Phone:</strong> {selectedRow?.phone}</p>
                        <p><strong>DOB:</strong> {selectedRow?.DOB}</p>
                    </div> */}
                    <table className="table table">
                        <tbody>
                            <tr>
                                <td> <p><strong>ID:</strong></p></td>
                                <td> <p>{selectedRow?._id}</p></td>
                            </tr>
                            <tr>
                                <td> <p><strong>Name:</strong></p></td>
                                <td> <p>{selectedRow?.name ? selectedRow?.name : "-"}</p></td>
                            </tr>
                            <tr>
                                <td> <p><strong>Username:</strong></p></td>
                                <td> <p>{selectedRow?.username ? selectedRow?.username : "-"}</p></td>
                            </tr>
                            <tr>
                                <td> <p><strong>Email:</strong></p></td>
                                <td> <p>{selectedRow?.email ? selectedRow?.email : "-"}</p></td>
                            </tr>
                            <tr>
                                <td> <p><strong>Phone:</strong></p></td>
                                <td> <p>{selectedRow?.phone ? selectedRow?.phone : "-"}</p></td>
                            </tr>
                            <tr>
                                <td> <p><strong>DOB:</strong></p></td>
                                <td> <p>{selectedRow?.DOB ? selectedRow?.DOB : "-"}</p></td>
                            </tr>
                        </tbody>
                    </table>
                </DialogContent>
                <DialogActions>
                    <Button className="btn" onClick={handleViewClose}>Close</Button>
                </DialogActions>
            </Dialog>
            {/* Edit Modal */}
            <Dialog open={isEditOpen} onClose={handleEditClose}>
                <DialogTitle>Edit Users</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        label="First Name"
                        type="text"
                        fullWidth
                        value={selectedRow?.firstName || ''}
                        onChange={(e) => setSelectedRow({ ...selectedRow, firstName: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        label="Last Name"
                        type="text"
                        fullWidth
                        value={selectedRow?.lastName || ''}
                        onChange={(e) => setSelectedRow({ ...selectedRow, lastName: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        label="Age"
                        type="number"
                        fullWidth
                        value={selectedRow?.age || ''}
                        onChange={(e) => setSelectedRow({ ...selectedRow, age: e.target.value })}
                    />
                </DialogContent>
                <DialogActions className="p-3">
                    <Button variant="contained" className="grey-btn" onClick={handleEditClose}>Cancel</Button>
                    <Button className="btn" variant="contained" onClick={handleEditSave}>Save</Button>
                </DialogActions>
            </Dialog>
            {/* change user status Modal */}
            <Dialog fullWidth={true} maxWidth="sm" open={isNewModalOpen} onClose={() => setIsNewModalOpen(false)}>
                <DialogTitle>{selectedRow?.user_status === 0 ? "Disable" : "Enable"}  user</DialogTitle>
                <DialogContent>
                    <p>Are you sure you want to {selectedRow?.user_status === 0 ? "disable" : "enable"} user ?</p>
                </DialogContent>
                <DialogActions>
                    <Button className="grey-btn" onClick={() => handleUserStatus()}>Yes</Button>
                    <Button className="btn" onClick={() => setIsNewModalOpen(false)}>No</Button>
                </DialogActions>
            </Dialog>

            {/* change creator status Modal */}
            <Dialog fullWidth={true} maxWidth="sm" open={isCreatorModalOpen} onClose={() => setIsCreatorModalOpen(false)}>
                <DialogTitle>{selectedRow?.creater_status === 1 ? "Disable" : "Enable"}  Creator</DialogTitle>
                <DialogContent>
                    <p>Are you sure you want to {selectedRow?.creater_status === 1 ? "disable" : "enable"} Creator ?</p>
                </DialogContent>
                <DialogActions>
                    <Button className="grey-btn" onClick={() => handleCreatorStatus()}>Yes</Button>
                    <Button className="btn" onClick={() => setIsCreatorModalOpen(false)}>No</Button>
                </DialogActions>
            </Dialog>

            {/* change Creator accept or reject */}
            <Dialog fullWidth={true} maxWidth="sm" open={userAcceptModalOpen} onClose={() => setUserAcceptModalOpen(false)}>
                <DialogTitle>Creator Request
                    <IconButton
                        aria-label="close"
                        onClick={() => setUserAcceptModalOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton></DialogTitle>
                <DialogContent>
                    <p>User want to join as a creator</p>
                </DialogContent>
                <DialogActions className="mb-2">
                    <Button className="btn" onClick={() => handleUserApprove('2')}>Approve</Button>
                    <Button className="grey-btn" onClick={() => handleUserApprove('3')}>Reject</Button>
                </DialogActions>
            </Dialog>
            <ReduxComponent />
        </>
    );
};

export default Users;
