import React, { useEffect, useState } from "react";
import SideBar from "../components/Sidebar";
import { IconButton, Button, TextField, Box, Dialog } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Swal from "sweetalert2";
import Dropdown from "../../src/components/dropdown";
import apiFunctions from "../apiKit/api";
import Loader from "../../src/components/Loader";
import { useDispatch, useSelector } from 'react-redux';
import ReduxComponent from "../components/reduxData";
import { fetchAPIs } from "../redux/slice/slice";

const FaqContent = () => {
    const [isOpen, setIsOpen] = useState(true);
    const { faqdata } = useSelector((state) => state.api); // Getting data from Redux store
    const [faqs, setFaqs] = useState([]); // Initialize with an empty array
    const [modalOpen, setModalOpen] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [faqForm, setFaqForm] = useState({ title: "", description: "" });
    const dispatch = useDispatch();

    useEffect(() => {
        if (faqdata) {
            setFaqs(faqdata);
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    }, [faqdata]);

    const handleOpen = () => {
        setIsOpen(!isOpen);
    };

    const handleModalOpen = (index = null) => {
        setEditIndex(index);
        if (index !== null && faqs[index]) {
            setFaqForm(faqs[index]); // Prefill form for edit
        } else {
            setFaqForm({ title: "", description: "" }); // Reset form for new FAQ
        }
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFaqForm({ ...faqForm, [name]: value });
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (editIndex !== null) {
            const updatedFaqs = [...faqs];
            updatedFaqs[editIndex] = faqForm;
            handleUpdateFAQ(faqForm, editIndex);
        } else {
            handleAddFAQ(faqForm);
        }
    };

    const handleAddFAQ = (data) => {
        apiFunctions.createFaq(data).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    text: res.message,
                    icon: 'success',
                });
                dispatch(fetchAPIs());
                setFaqs([...faqs, data]); // Update state with new FAQ
            } else {
                Swal.fire({
                    text: res.message,
                    icon: 'error',
                });
            }
            handleModalClose();
        }).catch(() => {
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error',
            });
        });
    };

    const handleUpdateFAQ = (data, index) => {
        apiFunctions.editFaq(data, data._id).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    text: res.message,
                    icon: 'success',
                });
                const updatedFaqs = [...faqs];
                updatedFaqs[index] = data;
                setFaqs(updatedFaqs);
            } else {
                Swal.fire({
                    text: res.message,
                    icon: 'error',
                });
            }
            handleModalClose();
        }).catch(() => {
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error',
            });
        });
    };

    const handleDelete = (index, faqId) => {
        Swal.fire({
            text: "Are you sure you want to delete this FAQ?",
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
        }).then((result) => {
            if (result.isConfirmed) {
                apiFunctions.deleteFaq(faqId).then((res) => {
                    if (res.status === 200) {
                        Swal.fire({
                            text: res.message,
                            icon: 'success',
                        });
                        const updatedFaqs = faqs.filter((_, i) => i !== index);
                        setFaqs(updatedFaqs); // Remove deleted FAQ from state
                    } else {
                        Swal.fire({
                            text: res.message,
                            icon: 'error',
                        });
                    }
                }).catch(() => {
                    Swal.fire({
                        text: 'An error occurred. Please try again later.',
                        icon: 'error',
                    });
                });
            }
        });
    };

    return (
        <>
            <div className="container-fluid ">
                <div className="row">
                    <div className={`${isOpen ? "col-lg-2 p-0" : "d-none"} sidebar_layout`}>
                        <SideBar />
                    </div>
                    <div className={`${isOpen ? "col-lg-10" : "col-12"} dashboard_card main_layout`}>
                        <div className="d-flex justify-content-between">
                            <IconButton onClick={handleOpen}>
                                <MenuIcon />
                            </IconButton>
                            <div className="logout_dropdown">
                                {/* <Logout /> */}
                                <Dropdown />
                            </div>
                        </div>
                        {isLoading ? <Loader /> :
                            <div className="row mt-3">
                                <div className="col-12 p-3">
                                    {/* <Card>
                                        <CardContent> */}
                                    <div className="row">
                                        <div className="col-lg-6 text-start">
                                            <h4 className="fw-bold">FAQ</h4>
                                        </div>
                                        <div className="col-lg-6 text-end">
                                            <Button className="btn" onClick={() => handleModalOpen()} variant="contained" endIcon={<AddIcon />}>Add New</Button>
                                        </div>
                                    </div>
                                    <div>
                                        {faqs.map((faq, index) => (
                                            <Accordion key={index}>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                    <Typography className="fw-bold" >{faq.title}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>{faq.description}</Typography>
                                                    <div className="text-end">
                                                        <IconButton onClick={() => handleModalOpen(index)}>
                                                            <EditIcon />
                                                        </IconButton>
                                                        <IconButton onClick={() => handleDelete(index, faq._id)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                        ))}
                                    </div>
                                    {/* </CardContent>
                                    </Card> */}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Dialog open={modalOpen} onClose={handleModalClose} maxWidth={'sm'} fullWidth={true} >
                <Box className="modal-box p-3">
                    <div className="d-flex justify-content-between">
                        <Typography variant="h6">{editIndex !== null ? "Edit FAQ" : "Add New FAQ"}</Typography>
                        <IconButton onClick={handleModalClose}><CloseRoundedIcon /></IconButton>
                    </div>
                    <form onSubmit={handleFormSubmit}>
                        <TextField
                            name="title"
                            label="Question"
                            value={faqForm.title}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <textarea
                            name="description"
                            className="form-control w-100"
                            rows={4}
                            label="Answer"
                            placeholder="Answer*"
                            value={faqForm.description}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <Button type="submit" className="btn mt-2" variant="contained" color="primary">Submit</Button>
                    </form>
                </Box>
            </Dialog>
            <ReduxComponent />
        </>
    );
};

export default FaqContent;
