export const pageRoutes = {
  login: "/",
  dashboard: "/dashboard",
  banners: "/banners",
  users: "/users",
  Inviteusers: "/invites",
  creators: "/creators",
  interests: "/interest",
  payments: "/payment",
  posts: "/posts",
  statictics: "/statictics",
  // creators:"/topcreators",
  article: "/article",
  addArticle: "/add-article",
  passwordChange: "/change-password",
  cmsHome: "/cms/home",
  FAQ: "/cms/faq",
  reports: "/reports",
  adminprivacy: "/cms/admin/privacy-policy",
  adminterms: "/cms/admin/terms-and-conditions",
  editprivacy: "/cms/edit/privacy-policy",
  editterms: "/cms/edit/terms-and-conditions",
  settings: "/cms/settings",
  notifications: "/notifications"


}