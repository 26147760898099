import React, { useState, useEffect } from "react";
import Card from '@mui/material/Card';
import { CardContent, Collapse } from "@mui/material";
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import { useLocation, Link } from "react-router-dom";
import "../custom.css";
import Logo from "../Images/Tealogo.png";
import { pageRoutes } from "../config/route";
import SummarizeIcon from '@mui/icons-material/Summarize';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import SettingsIcon from '@mui/icons-material/Settings';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import InterestsSharpIcon from '@mui/icons-material/InterestsSharp';
import BarChartIcon from '@mui/icons-material/BarChart';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import PaymentsIcon from '@mui/icons-material/Payments';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import VrpanoOutlinedIcon from '@mui/icons-material/VrpanoOutlined';
const SideBar = () => {
    const location = useLocation();
    const [openCms, setOpenCms] = useState(false);

    useEffect(() => {
        if (location.pathname.includes("cms")) {
            setOpenCms(true);
        } else {
            setOpenCms(false);
        }
    }, [location.pathname]);

    const handleCmsClick = () => {
        setOpenCms(!openCms);
    };

    return (
        <>
            <Card elevation={0} className="side-card scrollbar" id="custom-scroll">
                <CardContent>
                    <div className="mb-3">
                        <div className="text-center">
                            <img alt="" src={Logo} style={{ width: "", height: "45px" }} />
                        </div>
                    </div>
                    <div className="mt-2">
                        <Link to={pageRoutes.dashboard} className="link">
                            <div className={location.pathname.includes("dashboard") ? "mt-2 mx-4 d-flex nav-link active-link-text" : "d-flex mt-2 mx-4 nav-link"}>
                                <AutoAwesomeMosaicIcon />
                                <h6 className="ms-2 dash_text">Dashboard</h6>
                            </div>
                        </Link>
                    </div>
                    <div>
                        <Link to={pageRoutes.users} className="link">
                            <div className={location.pathname.includes("users") ? "mt-2 mx-4 d-flex nav-link active-link-text" : "mt-2 d-flex mx-4 nav-link"}>
                                <PeopleRoundedIcon />
                                <h6 className="ms-2 dash_text">Users</h6>
                            </div>
                        </Link>
                    </div>
                    <div>
                        <Link to={pageRoutes.creators} className="link">
                            <div className={location.pathname.includes("creators") ? "mt-2 mx-4 d-flex nav-link active-link-text" : "mt-2 d-flex mx-4 nav-link"}>
                                <Diversity3Icon />
                                <h6 className="ms-2 dash_text">Creators</h6>
                            </div>
                        </Link>
                    </div>
                    <div>
                        <Link to={pageRoutes.posts} className="link">
                            <div className={location.pathname.includes("posts") ? "mt-2 mx-4 d-flex nav-link active-link-text" : "mt-2 d-flex mx-4 nav-link"}>
                                <VrpanoOutlinedIcon />
                                <h6 className="ms-2 dash_text">Posts</h6>
                            </div>
                        </Link>
                    </div>
                    <div>
                        <Link to={pageRoutes.statictics} className="link">
                            <div className={location.pathname.includes("statictics") ? "mt-2 mx-4 d-flex nav-link active-link-text" : "mt-2 d-flex mx-4 nav-link"}>
                                <BarChartIcon />
                                <h6 className="ms-2 dash_text">Statictics</h6>
                            </div>
                        </Link>
                    </div>
                    <div>
                        <Link to={pageRoutes.payments} className="link">
                            <div className={location.pathname.includes("payment") ? "mt-2 mx-4 d-flex nav-link active-link-text" : "mt-2 d-flex mx-4 nav-link"}>
                                <PaymentsIcon />
                                <h6 className="ms-2 dash_text">Payment</h6>
                            </div>
                        </Link>
                    </div>
                    <div>
                        <Link to={pageRoutes.interests} className="link">
                            <div className={location.pathname.includes("interest") ? "mt-2 mx-4 d-flex nav-link active-link-text" : "mt-2 d-flex mx-4 nav-link"}>
                                <InterestsSharpIcon />
                                <h6 className="ms-2 dash_text">Interest</h6>
                            </div>
                        </Link>
                    </div>
                    <div>
                        <Link to={pageRoutes.notifications} className="link">
                            <div className={location.pathname.includes("notifications") ? "mt-2 mx-4 d-flex nav-link active-link-text" : "mt-2 d-flex mx-4 nav-link"}>
                                <NotificationsActiveIcon />
                                <h6 className="ms-2 dash_text">Notifications</h6>
                            </div>
                        </Link>
                    </div>
                    <div>
                        <Link to={pageRoutes.reports} className="link">
                            <div className={location.pathname.includes("reports") ? "mt-2 mx-4 d-flex nav-link active-link-text" : "mt-2 d-flex mx-4 nav-link"}>
                                <SummarizeIcon />
                                <h6 className="ms-2 dash_text">Reports</h6>
                            </div>
                        </Link>
                    </div>
                    <div>
                        <Link className="link">
                            <div className={openCms ? `mt-2 d-flex mx-4 nav-link cms-active` : `mt-2 d-flex mx-4 nav-link`} onClick={handleCmsClick}>
                                <BallotOutlinedIcon />
                                <h6 className="ms-2 dash_text">CMS</h6>
                                {openCms ? <ExpandLessIcon className="ms-4" /> : <ExpandMoreIcon className="ms-4" />}
                            </div>
                        </Link>
                    </div>
                    <Collapse className="bg-white" style={{ marginLeft: "20px" }} in={openCms}>
                        <div>
                            <Link to={pageRoutes.FAQ} className="link">
                                <div className={location.pathname.includes("faq") ? "mt-2 mx-4 d-flex nav-link active-link-text" : "mt-2 d-flex mx-4 nav-link"}>
                                    <LiveHelpIcon />
                                    <h6 className="ms-2 dash_text">FAQ</h6>
                                </div>
                            </Link>
                        </div>
                        <div>
                            <Link to={pageRoutes.adminprivacy} className="link">
                                <div className={location.pathname.includes("privacy") ? "mt-2 mx-4 d-flex nav-link active-link-text" : "mt-2 d-flex mx-4 nav-link"}>
                                    <PrivacyTipIcon />
                                    <h6 className="ms-2 dash_text">Privacy policy</h6>
                                </div>
                            </Link>
                        </div>
                        <div>
                            <Link to={pageRoutes.adminterms} className="link">
                                <div className={location.pathname.includes("terms") ? "mt-2 mx-4 d-flex nav-link active-link-text" : "mt-2 d-flex mx-4 nav-link"}>
                                    <StickyNote2Icon />
                                    <h6 className="ms-2 dash_text">Terms and conditions</h6>
                                </div>
                            </Link>
                        </div>
                        <div>
                            <Link to={pageRoutes.settings} className="link">
                                <div className={location.pathname.includes("settings") ? "mt-2 mx-4 d-flex nav-link active-link-text" : "mt-2 d-flex mx-4 nav-link"}>
                                    <SettingsIcon />
                                    <h6 className="ms-2 dash_text">Settings</h6>
                                </div>
                            </Link>
                        </div>
                    </Collapse>
                </CardContent>
            </Card>
        </>
    );
};

export default SideBar;

