import React, { useState, useEffect } from "react";
import { DataGrid } from '@mui/x-data-grid';
import "../assets/css/table.css";
import apiFunctions from "../apiKit/api";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Button, ButtonGroup, Card, CardContent, IconButton,
    Dialog, DialogTitle, DialogContent, DialogActions,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    TextField
} from "@mui/material";
import Swal from "sweetalert2";
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import { useDispatch } from "react-redux";
import { fetchAPIs } from "../redux/slice/slice";
const AdminPostTable = () => {
    const [rows, setRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [rowCount, setRowCount] = useState(0);
    const [isLastPage, setIsLastPage] = useState(false);
    const [isViewOpen, setIsViewOpen] = useState(false);
    const [filter, setFilter] = useState({ user_email: '', title: '', category: '' });
    const [filteredRows, setFilteredRows] = useState([]);
    const [count, setCount] = useState(0);
    const dispatch = useDispatch();

    const columns = [
        {
            field: 'title', disableColumnMenu: true,
            sortable: false, headerName: 'Title', flex: 1, resizable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'category', disableColumnMenu: true,
            sortable: false, headerName: 'Category', flex: 1, resizable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'tier', disableColumnMenu: true,
            sortable: false, headerName: 'Tier', flex: 1, resizable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'user_email', disableColumnMenu: true,
            sortable: false, headerName: 'Creator Email', flex: 1, resizable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'postDate', disableColumnMenu: true,
            sortable: false, headerName: 'Post Date', flex: 1, resizable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'Action',
            flex: 1,
            disableColumnMenu: true,
            sortable: false,
            headerName: 'Action',
            renderCell: (params) => (
                <ButtonGroup>
                    <IconButton onClick={() => handleViewOpen(params.row)}>
                        <VisibilityIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(params.row?.id)}>
                        <DeleteIcon />
                    </IconButton>
                </ButtonGroup>
            )
        }
    ];

    // Fetch data with pagination
    const fetchData = async (pageNo) => {
        setLoading(true);
        try {
            const res = await apiFunctions.getAllPosts(pageNo);
            if (res.status === 200) {
                if (res.data?.data !== '') {
                    console.log("resssss", res.data.data);
                    setCount(res?.data?.datacount);
                    const formattedRows = res.data?.data?.map((item) => ({
                        id: item._id,
                        title: item.title,
                        category: item.category,
                        tier: item.tier,
                        likes: item.likedcount,
                        comments: item.commentcount,
                        user_email:item.user_email,
                        postDate: new Date(item.createdAt).toLocaleDateString(),
                        coverimage: item.coverimage,
                        post: item.post
                    }));
                    setRows(formattedRows);
                    setRowCount(res.datacount);
                    setIsLastPage(res.isLast);
                    if (res.data?.data?.length < 10) {
                        setIsLastPage(true);
                    }
                } else {
                    setRows([]);
                    setRowCount(0);
                    setIsLastPage(true);
                }
            } else {
                setRows([]);
                setRowCount(0);
                setIsLastPage(true);
            }
        } catch (error) {
            console.error("Error fetching data :", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(page);
    }, [page]);
    useEffect(() => {
        const applyFilters = () => {
            const filtered = rows.filter((row) => {
                return (
                    (!filter.user_email || row.user_email?.toLowerCase().includes(filter.user_email.toLowerCase())) &&
                    (!filter.title || row.title?.toLowerCase().includes(filter.title.toLowerCase())) &&
                    (!filter.category || row.category?.toLowerCase().includes(filter.category.toLowerCase()))
                );
            });
            setFilteredRows(filtered);
        };
        applyFilters();
    }, [filter, rows]);


    const handleViewOpen = (row) => {
        setSelectedRow(row);
        setIsViewOpen(true);
    };

    const handleViewClose = () => {
        setIsViewOpen(false);
        setSelectedRow(null);
    };

    const handleDelete = (creatorPostId) => {
        Swal.fire({
            text: "Are you sure you want to delete this Post?",
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
        }).then((result) => {
            if (result.isConfirmed) {
                apiFunctions.deletCreatorPosts(creatorPostId).then((res) => {
                    if (res.status === 200) {
                        Swal.fire({
                            text: res.message,
                            icon: 'success',
                        });
                        fetchData(page);
                    } else {
                        Swal.fire({
                            text: res.message,
                            icon: 'error',
                        });
                    }
                }).catch(() => {
                    Swal.fire({
                        text: 'An error occurred. Please try again later.',
                        icon: 'error',
                    });
                });
            }
        });
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilter({ ...filter, [name]: value });
    };
    const handleClearFilter = () => {
        setFilter({ user_email: '', title: '', category: '' });
        fetchData(page)
    };

    return (
        <div>
            <Card elevation={4} className="p-0 mt-3">
                <CardContent className="p-0">
                    <Accordion elevation={3} className="mt-3">
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <h5>Filter Options</h5>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="row mb-3">
                                <div className="col-md-3">
                                    <TextField
                                        label="Filter by Creator Email"
                                        variant="outlined"
                                        fullWidth
                                        name="user_email"
                                        value={filter.user_email}
                                        onChange={handleFilterChange}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <TextField
                                        label="Filter by Title"
                                        variant="outlined"
                                        fullWidth
                                        name="title"
                                        value={filter.title}
                                        onChange={handleFilterChange}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <TextField
                                        label="Filter by Category"
                                        variant="outlined"
                                        fullWidth
                                        name="category"
                                        value={filter.category}
                                        onChange={handleFilterChange}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <button className="btn" onClick={handleClearFilter} >Clear</button>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <div className="table-container">
                        <DataGrid
                            rows={filteredRows}
                            columns={columns}
                            getRowId={(row) => row.id}
                            loading={loading}
                            disableColumnMenu
                            rowCount={rowCount}
                            localeText={{
                                noRowsLabel: 'No data found',
                            }}
                            autoHeight
                            pagination={false}
                            hideFooter={true}
                        />


                    </div>
                    {/* Custom Pagination */}
                    <div className="pagination">
                        <IconButton
                            disabled={page === 1}
                            onClick={() => setPage(page - 1)}
                        >
                            <KeyboardArrowLeftOutlinedIcon />
                        </IconButton>
                        <span>Page {page}</span>
                        <IconButton
                            disabled={isLastPage}
                            onClick={() => setPage(page + 1)}
                        >
                            <KeyboardArrowRightOutlinedIcon />
                        </IconButton>
                    </div>
                </CardContent>
            </Card>

            {/* View Modal */}
            <Dialog open={isViewOpen} onClose={handleViewClose} fullWidth maxWidth="sm">
                <DialogTitle>Post Detail</DialogTitle>
                <DialogContent>
                    <table className="table table">
                        <tbody>
                            <tr>
                                <td><strong>ID :</strong></td>
                                <td>{selectedRow?.id}</td>
                            </tr>
                            <tr>
                                <td><strong>Title :</strong></td>
                                <td>{selectedRow?.title}</td>
                            </tr>
                            <tr>
                                <td><strong>Category :</strong></td>
                                <td>{selectedRow?.category || "-"}</td>
                            </tr>
                            <tr>
                                <td><strong>Tier :</strong></td>
                                <td>{selectedRow?.tier || "-"}</td>
                            </tr>
                            <tr>
                                <td><strong>Posted By :</strong></td>
                                <td>{selectedRow?.user_email || "-"}</td>
                            </tr>
                            <tr>
                                <td><strong>Post Date :</strong></td>
                                <td>{selectedRow?.postDate || "-"}</td>
                            </tr>
                            <tr>
                                <td><strong>Post :</strong></td>
                                <td>
                                    {selectedRow?.post ? (
                                        <video autoPlay style={{ width: "270px", height: "auto" }}>
                                            <source src={selectedRow?.post} type="video/mp4" />
                                        </video>
                                    ) : (
                                        <img style={{ width: "270px", height: "auto" }} src={selectedRow?.coverimage} alt="demo" />
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </DialogContent>
                <DialogActions>
                    <Button className="btn" onClick={handleViewClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
export default AdminPostTable;
