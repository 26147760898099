import React, { useEffect, useState } from "react";
import SideBar from "../components/Sidebar";
import { IconButton } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import "../../src/custom.css";
import Loader from "../components/Loader";
import Dropdown from "../components/dropdown";
import ReduxComponent from "../components/reduxData";
import AdminPaymentHistoryTable from "../components/paymenthistory";
import AdminPayoutHistoryTable from "../components/payouthistory";
const Payments = () => {
    const [isOpen, setIsopen] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    }, []);

    const handleOpen = () => {
        setIsopen(!isOpen);
    };

    return (
        <>
            <div className="container-fluid p-0" style={{ overflow: 'hidden' }}>
                <div className="row">
                    <div className={`${isOpen ? "col-lg-2 mob-nav p-0" : "d-none"} sidebar_layout`}>
                        <SideBar />
                    </div>
                    <div className={`${isOpen ? "col-lg-10 col-12" : "col-12 w-100"} dashboard_card main_layout`} >
                        <div className="row">
                            <div className="d-flex w-100 justify">
                                <IconButton className="web-btn" onClick={handleOpen}>
                                    <MenuIcon />
                                </IconButton>
                                <IconButton className="mob-btn" data-bs-toggle="offcanvas" data-bs-target="#mob-canvas" aria-controls="mob-canvas">
                                    <MenuIcon />
                                </IconButton>
                                <div className="logout_dropdown">
                                    {/* <Logout /> */}
                                    <Dropdown />
                                </div>
                            </div>
                        </div>
                        {/* Page Contents */}
                        {isLoading ?
                            <Loader /> :
                            <div className="row p-3 mt-3 main">
                                <div className="col-12">
                                    <div className="d-flex justify">
                                        <h4 className="fw-bold">Payments</h4>
                                    </div>
                                    {/* Paymenthistory */}
                                    <AdminPaymentHistoryTable />
                                    {/* Payouthistory */}
                                    <AdminPayoutHistoryTable/>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="mob-canvas" aria-labelledby="mob-canvaslabel">
                <div className="offcanvas-header">
                    <IconButton data-bs-dismiss="offcanvas" aria-label="Close">
                        <CloseIcon style={{ height: '40px', width: '40px', color: 'white' }} />
                    </IconButton>
                </div>
                <div className="offcanvas-body p-0">
                    <SideBar />
                </div>
            </div>
            <ReduxComponent />
        </>
    );
};

export default Payments;
