import React, { useEffect, useState } from "react";
import { Card, CardContent, IconButton } from "@mui/material";
import { Menu as MenuIcon } from '@mui/icons-material';
import SideBar from "../../components/Sidebar";
import Loader from "../../components/Loader";
import Dropdown from "../../components/dropdown";
import "../../custom.css";
import { AgCharts } from "ag-charts-react";
import { useSelector } from "react-redux";
import apiFunctions from "../../apiKit/api";

const Statictics = () => {
    const [isOpen, setIsOpen] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedYear, setSelectedYear] = useState("");
    const { chartYeardata, chartVideoYeardata, chartPayYeardata } = useSelector((state) => state.api);
    const [userOptions, setUserOptions] = useState({});
    const [videoOptions, setVideoOptions] = useState({});
    const [payoptions, setPayOptions] = useState({});

    const handleOpen = () => {
        setIsOpen(!isOpen);
    }

    const getUserdata = () => {
        apiFunctions.getChartDatasApi("").then((res) => {
            if (res.status === 200) {
                console.log("user charts", res.data.data);
                setUserOptions({
                    title: {
                        text: "Users by Month",
                    },
                    data: res?.data?.data?.[0]?.month,
                    series: [
                        {
                            type: "line",
                            xKey: "month",
                            xName: "Month",
                            yKey: "monthCount",
                            yName: "Users",
                            interpolation: { type: "smooth" },
                        },
                    ],
                })
            } else {
                console.log("Failed to fetch user charts");
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const getVideodata = () => {
        apiFunctions.getChartVideoDatasApi("").then((res) => {
            if (res.status === 200) {
                console.log("Videos charts", res.data.data);
                setVideoOptions({
                    title: {
                        text: "Videos by Month",
                    },
                    data: res?.data?.data?.[0]?.month,
                    series: [
                        {
                            type: "bar",
                            xKey: "month",
                            xName: "Month",
                            yKey: "videoCount",
                            yName: "Videos",
                            interpolation: { type: "smooth" },
                        },
                    ],
                })
            } else {
                console.log("Failed to fetch user charts");
            }
        }).catch((err) => {
            console.log(err);
        });
    }
    const getPaymentdata = () => {
        apiFunctions.getChartPaymentDatasApi("").then((res) => {
            if (res.status === 200) {
                console.log("Pay charts", res.data.data);
                setPayOptions({
                    title: {
                        text: "Payments by Month",
                    },
                    data: res?.data?.data?.[0]?.month,
                    series: [
                        {
                            type: "line",
                            xKey: "month",
                            xName: "Month",
                            yKey: "totalPaymentAmount",
                            yName: "Payment",
                        }
                    ],
                })
            } else {
                console.log("Failed to fetch user charts");
            }
        }).catch((err) => {
            console.log(err);
        });
    }
    useEffect(() => {
        setTimeout(() => setIsLoading(false), 2000);
        console.log(chartYeardata, chartVideoYeardata);
        getUserdata();
        getVideodata();
        getPaymentdata();
    }, []);


    const handleUserYearchange = (e) => {
        e.preventDefault();
        apiFunctions.getChartDatasApi(e.target.value).then((res) => {
            if (res.status === 200) {
                console.log("user charts", res.data.data.month);
                setUserOptions({
                    title: {
                        text: "Users by Month",
                    },
                    data: res?.data?.data?.[0]?.month,
                    series: [
                        {
                            type: "line",
                            xKey: "month",
                            xName: "Month",
                            yKey: "monthCount",
                            yName: "Users",
                            interpolation: { type: "smooth" },
                        },
                    ],
                })
            } else {
                console.log("Failed to fetch user charts");
            }
        }).catch((err) => {
            console.log(err);
        });

    }
    const handleVideoYearchange = (e) => {
        e.preventDefault();
        apiFunctions.getChartVideoDatasApi(e.target.value).then((res) => {
            if (res.status === 200) {
                console.log("Videos charts", res.data.data.month);
                setVideoOptions({
                    title: {
                        text: "Videos by Month",
                    },
                    data: res?.data?.data?.[0]?.month,
                    series: [
                        {
                            type: "bar",
                            xKey: "month",
                            xName: "Month",
                            yKey: "videoCount",
                            yName: "Videos",
                            interpolation: { type: "smooth" },
                        },
                    ],
                })
            } else {
                console.log("Failed to fetch user charts");
            }
        }).catch((err) => {
            console.log(err);
        });
    }
    const handlePayYearchange = (e) => {
        e.preventDefault();
        apiFunctions.getChartPaymentDatasApi(e.target.value).then((res) => {
            if (res.status === 200) {
                console.log("Payments charts", res.data.data.month);
                setPayOptions({
                    title: {
                        text: "Payments by Month",
                    },
                    data: res?.data?.data?.[0]?.month,
                    series: [
                        {
                            type: "line",
                            xKey: "month",
                            xName: "Month",
                            yKey: "totalPaymentAmount",
                            yName: "Payment",
                        }
                    ],
                })
            } else {
                console.log("Failed to fetch user charts");
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    return (
        <>
            <div className="container-fluid p-0" style={{ overflow: 'hidden' }}>
                <div className="row">
                    <div className={`${isOpen ? "col-lg-2 mob-nav p-0" : "d-none"} sidebar_layout`}>
                        <SideBar />
                    </div>
                    <div className={`${isOpen ? "col-lg-10 col-12" : "col-12 w-100"} dashboard_card main_layout`} >
                        <div className="row">
                            <div className="d-flex w-100 justify">
                                <IconButton className="web-btn" onClick={handleOpen}>
                                    <MenuIcon />
                                </IconButton>
                                <IconButton className="mob-btn" data-bs-toggle="offcanvas" data-bs-target="#mob-canvas" aria-controls="mob-canvas">
                                    <MenuIcon />
                                </IconButton>
                                <div className="logout_dropdown">
                                    {/* <Logout /> */}
                                    <Dropdown />
                                </div>
                            </div>
                        </div>
                        {/* Page Contents */}
                        {isLoading ? <Loader /> :
                            <div className="row p-3 mt-3 main">
                                <div className="col-12">
                                    <h4>Statictics</h4>
                                    <Card className="mt-3">
                                        <CardContent>
                                            <div className="d-flex justify">
                                                <h5><b>Users</b></h5>
                                                <select value={selectedYear} onChange={handleUserYearchange} className="form-select" style={{ width: "200px" }}>
                                                    <option value="">choose year</option>
                                                    {chartYeardata?.map((item, index) =>
                                                        <option key={index} value={item.year}>{item.year}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <AgCharts options={userOptions} />
                                        </CardContent>
                                    </Card>
                                    <Card className="mt-3">
                                        <CardContent>
                                            <div className="d-flex justify">
                                                <h5><b>Videos</b></h5>
                                                <select value={selectedYear} onChange={handleVideoYearchange} className="form-select" style={{ width: "200px" }}>
                                                    <option value="">choose year</option>
                                                    {chartVideoYeardata?.map((item, index) =>
                                                        <option key={index} value={item.year}>{item.year}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <AgCharts options={videoOptions} />
                                        </CardContent>
                                    </Card>
                                    <Card className="mt-3">
                                        <CardContent>
                                            <div className="d-flex justify">
                                                <h5><b>Payments</b></h5>
                                                <select value={selectedYear} onChange={handlePayYearchange} className="form-select" style={{ width: "200px" }}>
                                                    <option value="">choose year</option>
                                                    {chartPayYeardata?.map((item, index) =>
                                                        <option key={index} value={item.year}>{item.year}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <AgCharts options={payoptions} />
                                        </CardContent>
                                    </Card>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default Statictics;
